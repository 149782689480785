import _ from "lodash";
import api from "../../api";

export default {
  state: {
    benefits: [],
    fetched: false,
    loading: false,
  },

  mutations: {
    BENEFITS_START_FETCHING: (state) => {
      state.loading = true;
    },

    BENEFITS_FETCHED: (state, benefits) => {
      state.benefits = benefits;
      state.fetched = true;
      state.loading = false;
    },

    BENEFIT_CREATED: (state, benefit) => {
      state.benefits.push(benefit);
    },

    BENEFIT_UPDATED: (state, benefit) => {
      const object = _.find(state.benefits, { id: benefit.id });

      if (object) {
        const index = state.benefits.indexOf(object);
        state.benefits.splice(index, 1, benefit);
      }
    },

    BENEFIT_DELETED: (state, id) => {
      const object = _.find(state.benefits, id);

      if (object) {
        const index = state.benefits.indexOf(object);
        state.benefits.splice(index, 1);
      }
    },
  },

  actions: {
    fetchBenefits({ commit }) {
      commit("BENEFITS_START_FETCHING");
      return api.getBenefits().then((benefits) => {
        commit("BENEFITS_FETCHED", benefits);
      });
    },

    async createBenefit({ commit }, payload) {
      try {
        const benefit = await api.createBenefit(payload);
        commit("BENEFIT_CREATED", benefit);
        return benefit;
      } catch (error) {
        this.$noty.error(this.$i18n.t("admin.errors.internalError"));
      }
    },

    async updateBenefit({ commit }, payload) {
      try {
        const benefit = await api.updateBenefit(payload);
        commit("BENEFIT_UPDATED", benefit);
        return benefit;
      } catch (error) {
        this.$noty.error(this.$i18n.t("admin.errors.internalError"));
      }
    },

    async deleteBenefit({ commit }, id) {
      try {
        await api.deleteBenefit(id);
        commit("BENEFIT_DELETED", id);
      } catch (error) {
        this.$noty.error(this.$i18n.t("admin.errors.internalError"));
      }
    },
  },
};
