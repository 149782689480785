<template>
  <div class="app-menu d-print-none">
    <router-link
      :to="{
        name: this.hasPermission(permissionEnum.WebsiteManage)
          ? 'websites'
          : 'requests',
      }"
      class="logo-container"
      @click.native="$store.dispatch('reloadRequests')"
    >
      <img
        alt="Elevent's logo"
        class="logo"
        src="../../assets/logo-white.svg"
      />
    </router-link>
    <ul class="nav menu">
      <li
        v-if="canManageWebsites"
        :class="{ active: isCurrentPage('websites') }"
        class="menu-item"
      >
        <router-link :to="{ name: 'websites' }">
          <fa-icon :icon="['fal', 'sitemap']" class="menu-icon"></fa-icon>
          Sites
        </router-link>
      </li>
      <li
        v-if="canManageGlobalUsers"
        :class="{ active: isCurrentPage('users') }"
        class="menu-item"
      >
        <router-link :to="{ name: 'users' }">
          <fa-icon :icon="['fal', 'users']" class="menu-icon"></fa-icon>
          Users
        </router-link>
      </li>
      <li
        v-if="canManageBenefits"
        :class="{ active: isCurrentPage('benefits') }"
        class="menu-item"
      >
        <router-link :to="{ name: 'benefits' }">
          <fa-icon :icon="['fal', 'benefits']" class="menu-icon"></fa-icon>
          Benefits
        </router-link>
      </li>

      <li
        v-if="hasDashboard"
        :class="{ active: isCurrentPage('dashboard') }"
        class="menu-item"
      >
        <router-link :to="{ name: 'dashboard' }">
          <fa-icon :icon="['fal', 'house-chimney']" class="menu-icon"></fa-icon>
          {{ $t("admin.menu.dashboard") }}
        </router-link>
      </li>
      <li
        v-if="canManageQuestions"
        :class="{ active: isCurrentPage('questions') }"
        class="menu-item"
      >
        <router-link :to="{ name: 'questions' }">
          <fa-icon
            :icon="['fal', 'question']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.questions") }}
        </router-link>
      </li>
      <li
        v-if="canViewSubmissions"
        :class="{ active: isCurrentPage('requests') }"
        class="menu-item"
        @click="$store.dispatch('reloadRequests')"
      >
        <router-link :to="{ name: 'requests' }">
          <fa-icon :icon="['fal', 'copy']" class="menu-icon"></fa-icon>
          {{ $t("admin.menu.requests") }}
        </router-link>
      </li>
      <li
        v-if="canManageFields"
        :class="{ active: isCurrentPage('profile-fields') }"
        class="menu-item"
      >
        <router-link :to="{ name: 'profile-fields' }">
          <fa-icon
            :icon="['fal', 'address-card']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.profileFields") }}
        </router-link>
      </li>
      <li v-if="dropBudgetVisible" class="menu-item">
        <a @click.prevent="toggleReports">
          <fa-icon
            :icon="['fal', 'chart-pie']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.reports") }}
          <span :class="setDropdownMargin()" class="float-right">
            <fa-icon
              v-if="!showReports"
              :icon="['fas', 'chevron-down']"
            ></fa-icon>
            <fa-icon v-else :icon="['fas', 'chevron-up']"></fa-icon>
          </span>
        </a>
      </li>
      <li
        v-if="isActualBudgetVisible"
        :class="{ active: isCurrentPage('reports.actual-budget') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'reports.actual-budget' }">
          <fa-icon
            :icon="['fal', 'sack-dollar']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.actualVsBudget") }}
        </router-link>
      </li>
      <li
        v-if="isComparativeBudgetVisible"
        :class="{ active: isCurrentPage('reports.comparative') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'reports.comparative' }">
          <fa-icon
            :icon="['fal', 'chart-simple']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.comparative") }}
        </router-link>
      </li>
      <li v-if="dropSettingsVisible" class="menu-item">
        <a @click.prevent="toggleSettings">
          <fa-icon
            :icon="['fal', 'gear']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.settings") }}
          <span :class="setDropdownMargin()" class="float-right">
            <fa-icon
              v-if="!showSettings"
              :icon="['fas', 'chevron-down']"
            ></fa-icon>
            <fa-icon v-else :icon="['fas', 'chevron-up']"></fa-icon>
          </span>
        </a>
      </li>
      <li
        v-if="isManageSettingVisible"
        :class="{ active: isCurrentPage('settings') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'settings' }">
          <fa-icon
            :icon="['fal', 'wrench']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.generalSettings") }}
        </router-link>
      </li>
      <li
        v-if="isManageBudgetVisible"
        :class="{ active: isCurrentPage('budgets') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'budgets.index' }">
          <fa-icon
            :icon="['fal', 'dollar-sign']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.budgets") }}
        </router-link>
      </li>
      <li
        v-if="isManageGroupVisible"
        :class="{ active: isCurrentPage('groups') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'groups.index' }">
          <fa-icon
            :icon="['fal', 'users']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.groups") }}
        </router-link>
      </li>
      <li
        v-if="isManageUserOwnVisible"
        :class="{ active: isCurrentPage('site-users') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'site-users' }">
          <fa-icon
            :icon="['fal', 'user']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.users") }}
        </router-link>
      </li>
      <li
        v-if="isManageEmailVisible"
        :class="{ active: isCurrentPage('email-contents') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'email-contents' }">
          <fa-icon
            :icon="['fal', 'envelope']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.emailTemplates") }}
        </router-link>
      </li>
      <li
        v-if="isManagePageVisible"
        :class="{ active: isCurrentPage('pages') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'pages' }">
          <fa-icon
            :icon="['fal', 'message']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.pages") }}
        </router-link>
      </li>
      <li
        v-if="isManageStyleOwnVisible"
        :class="{ active: isCurrentPage('styles') }"
        class="menu-item nested"
      >
        <router-link :to="{ name: 'styles' }">
          <fa-icon
            :icon="['fal', 'droplet']"
            class="menu-icon"
            fixed-width
          ></fa-icon>
          {{ $t("admin.menu.styles") }}
        </router-link>
      </li>
    </ul>
    <img
      alt="Elevent logo"
      class="elevent-logo mt-auto p-2"
      src="../../assets/elevent-logo.svg"
    />
  </div>
</template>

<script>
import licenses from "@/mixins/licenses";
import userData from "@/mixins/user-data";
import permissions from "@/mixins/permissions";
import i18n from "@/i18n";

export default {
  mixins: [userData, licenses, permissions],
  data() {
    return {
      showReports:
        this.isCurrentPage("reports.actual-budget") ||
        this.isCurrentPage("reports.comparative"),
      showSettings:
        this.isCurrentPage("settings") ||
        this.isCurrentPage("budgets") ||
        this.isCurrentPage("groups") ||
        this.isCurrentPage("site-users") ||
        this.isCurrentPage("email-contents") ||
        this.isCurrentPage("pages") ||
        this.isCurrentPage("styles"),
    };
  },
  computed: {
    canManageBenefits() {
      return this.hasPermission(this.permissionEnum.BenefitsManage);
    },
    canManageFields() {
      return (
        this.hasPermission(this.permissionEnum.ProfileFieldsManage) &&
        this.hasLicenseFeature(this.featureEnum.PROFILE_FIELDS)
      );
    },
    canManageGlobalUsers() {
      return this.hasPermission(this.permissionEnum.UsersManage);
    },
    canManageQuestions() {
      return (
        this.hasPermission(this.permissionEnum.QuestionManage) &&
        this.hasLicenseFeature(this.featureEnum.QUESTIONS)
      );
    },
    canManageWebsites() {
      return this.hasPermission(this.permissionEnum.WebsiteManage);
    },
    canViewSubmissions() {
      return (
        (this.hasPermission(this.permissionEnum.SubmissionsView) ||
          this.hasPermission(this.permissionEnum.SubmissionsViewOwn)) &&
        this.hasLicenseFeature(this.featureEnum.SUBMISSIONS)
      );
    },
    currentLanguage() {
      return i18n.locale;
    },
    dropBudgetVisible() {
      return (
        this.hasLicenseFeature(this.featureEnum.BUDGETS) &&
        (this.hasPermission(this.permissionEnum.BudgetsView) ||
          this.hasPermission(this.permissionEnum.BudgetComparativeView))
      );
    },
    dropSettingsVisible() {
      return (
        this.hasPermission(this.permissionEnum.SettingsManage) ||
        this.hasPermission(this.permissionEnum.BudgetsManage) ||
        this.hasPermission(this.permissionEnum.GroupsManage) ||
        this.hasPermission(this.permissionEnum.UsersManageOwn) ||
        this.hasPermission(this.permissionEnum.EmailManage) ||
        this.hasPermission(this.permissionEnum.PagesManage) ||
        this.hasPermission(this.permissionEnum.StylesManageOwn)
      );
    },
    hasDashboard() {
      return (
        this.hasPermission(this.permissionEnum.DashboardView) &&
        this.hasLicenseFeature(this.featureEnum.DASHBOARD)
      );
    },
    isActualBudgetVisible() {
      return (
        this.hasPermission(this.permissionEnum.BudgetsView) &&
        this.hasLicenseFeature(this.featureEnum.BUDGETS) &&
        this.showReports
      );
    },
    isComparativeBudgetVisible() {
      return (
        this.hasPermission(this.permissionEnum.BudgetComparativeView) &&
        this.showReports
      );
    },
    isManageBudgetVisible() {
      return (
        this.hasLicenseFeature(this.featureEnum.BUDGETS) &&
        this.hasPermission(this.permissionEnum.BudgetsManage) &&
        this.showSettings
      );
    },
    isManageEmailVisible() {
      return (
        this.hasPermission(this.permissionEnum.EmailManage) &&
        this.hasLicenseFeature(this.featureEnum.EMAIL_CONTENTS) &&
        this.showSettings
      );
    },
    isManageGroupVisible() {
      return (
        this.hasLicenseFeature(this.featureEnum.USERS) &&
        this.hasPermission(this.permissionEnum.GroupsManage) &&
        this.showSettings
      );
    },
    isManagePageVisible() {
      return (
        this.hasPermission(this.permissionEnum.PagesManage) &&
        this.hasLicenseFeature(this.featureEnum.PAGES) &&
        this.showSettings
      );
    },
    isManageSettingVisible() {
      return (
        this.hasPermission(this.permissionEnum.SettingsManage) &&
        this.showSettings
      );
    },
    isManageStyleOwnVisible() {
      return (
        this.hasPermission(this.permissionEnum.StylesManageOwn) &&
        this.hasLicenseFeature(this.featureEnum.STYLES) &&
        this.showSettings
      );
    },
    isManageUserOwnVisible() {
      return (
        this.hasLicenseFeature(this.featureEnum.USERS) &&
        this.hasPermission(this.permissionEnum.UsersManageOwn) &&
        this.showSettings
      );
    },
  },
  methods: {
    toggleReports() {
      this.showReports = !this.showReports;
    },
    toggleSettings() {
      this.showSettings = !this.showSettings;
    },
    setDropdownMargin() {
      if (this.currentLanguage === "en") {
        return "mr-4";
      } else {
        return "mr-1";
      }
    },
    isCurrentPage(val) {
      return this.$store.state.navigation.activeMenu === val;
    },
  },
};
</script>
