/* eslint-disable camelcase */

import config from "../config";
import http from "./http";
import resource from "./resource";

const currentUser = resource(`${config.api.path}/users`);

const questions = resource(
  `${config.api.path}/websites/:websiteId/questions/:id`,
  {
    updateOrder: {
      method: "PUT",
      url: `${config.api.path}/websites/:websiteId/question/order`,
    },
  },
);

const properties = resource(`${config.api.path}/properties/:id`);

const sharedProperties = resource(
  `${config.api.path}/websites/:websiteId/submissions/:submissionId/properties/:propertyId/shared/:id`,
);

const choices = resource(
  `${config.api.path}/websites/:websiteId/questions/:questionId/choices/:id`,
  {
    updateOrder: {
      method: "PUT",
      url: `${config.api.path}/websites/:websiteId/question/:questionId/choices/order`,
    },
  },
);

const budgets = resource(`${config.api.path}/websites/:websiteId/budgets/:id`);
const accounts = resource(
  `${config.api.path}/websites/:websiteId/accounts/:id`,
  {
    getLatest: {
      method: "GET",
      url: `${config.api.path}/websites/:websiteId/accounts/latest`,
    },
  },
);
const accountBudgets = resource(
  `${config.api.path}/websites/:websiteId/account-budgets/:id`,
  {
    updateOrder: {
      method: "PUT",
      url: `${config.api.path}/websites/:websiteId/account-budget/order`,
    },
  },
);
const products = resource(
  `${config.api.path}/websites/:websiteId/products/:id`,
);

const sponsorshipBudgets = resource(
  `${config.api.path}/websites/:websiteId/sponsorship-budgets/:id`,
  {
    getBySubmission: {
      url: `${config.api.path}/websites/:websiteId/submissions/:submissionId/sponsorship-budgets`,
    },
  },
);

const sponsorshipBudgetAccounts = resource(
  `${config.api.path}/websites/:websiteId/sponsorship-budget-accounts/:id`,
);
const sponsorshipBudgetAccountProducts = resource(
  `${config.api.path}/websites/:websiteId/sponsorship-budget-account-products/:id`,
);

const outgoingEmails = resource(
  `${config.api.path}/websites/:websiteId/outgoing-emails/:id`,
  {
    createDraft: {
      method: "POST",
      url: `${config.api.path}/websites/:websiteId/outgoing-emails/drafts`,
    },
    attachFiles: {
      method: "POST",
      url: `${config.api.path}/websites/:websiteId/outgoing-emails/:emailId/attach-files`,
    },
    deleteFile: {
      method: "DELETE",
      url: `${config.api.path}/websites/:websiteId/outgoing-emails/:emailId/delete-file/:fileId`,
    },
  },
);

const tags = resource(`${config.api.path}/websites/:websiteId/tags/:id`);

const reports = resource(`${config.api.path}/websites/:websiteId/reports/:id`, {
  createActualVsBudget: {
    method: "POST",
    url: `${config.api.path}/websites/:websiteId/reports/actual-vs-budget`,
  },
  createComparativeReport: {
    method: "POST",
    url: `${config.api.path}/websites/:websiteId/reports/comparative`,
  },
});

const propertyReports = resource(`${config.api.path}/cakemix/reports/:id`);

const propertyComparativeReports = resource(
  `${config.api.path}/cakemix/comparative-reports/:id`,
);

const groups = resource(`${config.api.path}/websites/:websiteId/groups/:id`, {
  fetchGroupRelations: {
    method: "GET",
    url: `${config.api.path}/websites/:websiteId/groups/relations/:id`,
  },
  createGroupRelation: {
    method: "POST",
    url: `${config.api.path}/websites/:websiteId/groups/relations/`,
  },
  updateGroupRelation: {
    method: "PUT",
    url: `${config.api.path}/websites/:websiteId/groups/relations/:id`,
  },
  deleteGroupRelation: {
    method: "DELETE",
    url: `${config.api.path}/websites/:websiteId/groups/relations/:id`,
  },
});

const kpis = resource(`${config.api.path}/websites/:websiteId/kpis`);

const userPreferences = resource(`${config.api.path}/user-preferences`, {
  saveConfig: {
    method: "PUT",
    url: `${config.api.path}/user-preferences/:key`,
  },
});

export default {
  accounts,
  budgets,
  accountBudgets,
  products,
  sponsorshipBudgets,
  sponsorshipBudgetAccounts,
  sponsorshipBudgetAccountProducts,
  outgoingEmails,
  currentUser,
  questions,
  properties,
  sharedProperties,
  choices,
  tags,
  reports,
  propertyReports,
  propertyComparativeReports,
  groups,
  kpis,
  userPreferences,
  getAuthToken({ email, password }, lang = "en") {
    return http.post(`${config.api.path}/login?lang=${lang}`, {
      email,
      password,
      grant_type: config.api.grantType,
      client_id: config.api.clientId,
      client_secret: config.api.clientSecret,
      referer: "brickroad",
    });
  },
  verifyTwoFactorCode(code, email, lang = "en") {
    return http.post(`${config.api.path}/two-factor?lang=${lang}`, {
      code,
      email,
    });
  },
  forgotPassword(payload) {
    return http.post(`${config.api.path}/password/reset-link`, payload);
  },
  resetPassword(payload) {
    return http.post(`${config.api.path}/password/reset`, payload);
  },
  changePassword(form, lang = "en") {
    return http.put(`${config.api.path}/me/password?lang=${lang}`, form);
  },
  refreshToken(payload) {
    return http.post(`${config.api.path}/token/refresh`, payload);
  },
  /**
   * Websites
   */
  getWebsites(payload) {
    return http.get(`${config.api.path}/websites`, payload);
  },
  createWebsite(payload) {
    return http.post(`${config.api.path}/websites`, payload);
  },
  fetchWebsite(payload) {
    return http.get(`${config.api.path}/websites/${payload}`, payload);
  },
  editWebsite(site) {
    return http.put(`${config.api.path}/websites/${site.id}`, site);
  },
  deleteWebsite(payload) {
    return http.delete(`${config.api.path}/websites/${payload.id}`);
  },
  getWebsiteRoles(websiteId) {
    return http.get(`${config.api.path}/websites/${websiteId}/roles`);
  },
  getLicenses() {
    return http.get(`${config.api.path}/licenses`);
  },
  getPermissions() {
    return http.get(`${config.api.path}/permissions`);
  },
  getWebsiteLicense(licenseId) {
    return http.get(`${config.api.path}/licenses/${licenseId}`);
  },
  getFeatures() {
    return http.get(`${config.api.path}/features`);
  },
  copyWebsite({ websiteId, destinationId }) {
    return http.post(
      `${config.api.path}/websites/copy/${websiteId}/to/${destinationId}`,
    );
  },
  /**
   * Users
   */
  getUsers(payload) {
    return http.get(`${config.api.path}/users`, payload);
  },
  getUser(userId) {
    return http.get(`${config.api.path}/users/${userId}`);
  },
  getUserByWebsite(userId, websiteId) {
    return http.get(`${config.api.path}/websites/${websiteId}/users/${userId}`);
  },
  createUser(payload) {
    return http.post(
      `${config.api.path}/websites/${payload.website_id}/users`,
      payload,
    );
  },
  updateUser(payload) {
    return http.put(
      `${config.api.path}/websites/${payload.website_id}/users/${payload.id}`,
      payload,
    );
  },
  deleteUser(payload) {
    return http.delete(
      `${config.api.path}/websites/${payload.websiteId}/users/${payload.userId}`,
    );
  },
  sendWelcomeEmail(payload) {
    return http.post(
      `${config.api.path}/websites/${payload.websiteId}/users/${payload.userId}/welcome-email`,
    );
  },
  copyLoginLink(payload) {
    return http.get(
      `${config.api.path}/websites/${payload.websiteId}/users/${payload.userId}/login-link`,
    );
  },
  getRoles(payload) {
    return http.get(`${config.api.path}/roles`, payload);
  },
  generateUserPassword(payload) {
    return http.post(
      `${config.api.path}/websites/${payload.website_id}/users/${payload.id}/password`,
    );
  },
  getSiteUsers(websiteId) {
    return http.get(`${config.api.path}/websites/${websiteId}/users`);
  },
  /**
   * Profile fields
   */
  getProfileFields(payload) {
    return http.get(
      `${config.api.path}/websites/${payload}/profile-fields`,
      payload,
    );
  },
  changeProfileFieldsOrder({ websiteId, order }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/profile-field/order`,
      { order },
    );
  },
  getCurrentProfileField(payload) {
    return http.get(
      `${config.api.path}/websites/${payload.websiteId}/profile-field/${payload.profileId}`,
      payload,
    );
  },
  editCurrentProfileField(payload) {
    return http.put(
      `${config.api.path}/websites/${payload.websiteId}/profile-fields/${payload.profileId}`,
      payload,
    );
  },
  /**
   * Pages
   */
  getPages(payload) {
    return http.get(`${config.api.path}/websites/${payload}/pages`, payload);
  },
  getCurrentPage(payload) {
    return http.get(
      `${config.api.path}/websites/${payload.websiteId}/pages/${payload.pageId}`,
      payload,
    );
  },
  editCurrentPage(payload) {
    return http.put(
      `${config.api.path}/websites/${payload.websiteId}/pages/${payload.pageId}`,
      payload,
    );
  },
  /**
   * Styles
   */
  getCurrentStyle(payload) {
    if (payload === "null") {
      return false;
    }
    return http.get(`${config.api.path}/websites/${payload}/styles`, payload);
  },
  editCurrentStyle(payload) {
    return http.put(
      `${config.api.path}/websites/${payload.websiteId}/styles`,
      payload,
    );
  },
  /**
   * Settings
   */
  getCurrentSettings(payload) {
    return http.get(`${config.api.path}/websites/${payload}/settings`);
  },
  editCurrentSettings(payload) {
    return http.post(
      `${config.api.path}/websites/${payload.websiteId}/settings`,
      payload,
    );
  },
  /**
   * Emails
   */
  getEmails(payload) {
    return http.get(
      `${config.api.path}/websites/${payload}/email-notifications`,
      payload,
    );
  },
  getCurrentEmail(payload) {
    return http.get(
      `${config.api.path}/websites/${payload.websiteId}/email-notifications/${payload.emailId}`,
      payload,
    );
  },
  editCurrentEmail(payload) {
    return http.put(
      `${config.api.path}/websites/${payload.websiteId}/email-notifications/${payload.id}`,
      payload,
    );
  },
  /**
   * Requests
   */
  getRequests(websiteId, params) {
    return http.get(`${config.api.path}/websites/${websiteId}/submissions`, {
      params,
    });
  },
  searchRequests(websiteId, params) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/query`,
      { params },
    );
  },
  getFiltersChoices(websiteId) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/filters-choices`,
    );
  },
  getFiltersUsed(websiteId) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/filters-used`,
    );
  },
  getFiltersMaxValues(websiteId) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/filters-max-values`,
    );
  },
  downloadCsv(websiteId, params) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/export/csv`,
      { params },
    );
  },
  downloadPdf(websiteId, locale, params) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/export/pdf?lang=${locale}`,
      { params },
    );
  },
  downloadSpf(propertyId) {
    return http.get(`${config.api.path}/properties/${propertyId}/spf`);
  },
  getRequestsBudgetSumamry(websiteId, params) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/budget-summary`,
      { params },
    );
  },
  getRequestsAfterId(websiteId, id) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/after/${id}`,
    );
  },
  getRequestsByAccountBudget(websiteId, accountBudgetId) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submissions/account-budget/${accountBudgetId}`,
    );
  },
  getCurrentRequest(payload) {
    return http.get(
      `${config.api.path}/websites/${payload.websiteId}/submission/${payload.submissionId}`,
    );
  },
  deleteRequest(payload) {
    return http.delete(
      `${config.api.path}/websites/${payload.websiteId}/submission/${payload.submissionId}`,
    );
  },
  downloadRequest({ websiteId, submissionId, lang = "en" }) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/submission/${submissionId}/download?lang=${lang}`,
    );
  },
  editRequestInput({ websiteId, submissionId, inputId, value }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/submissions/${submissionId}/inputs/${inputId}`,
      { value },
    );
  },
  deleteRequestInput({ websiteId, submissionId, inputId, value }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/submissions/${submissionId}/delete-inputs/${inputId}`,
      { value },
    );
  },
  editRequestProfileInput({ websiteId, submissionId, inputName, value }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/submissions/${submissionId}/profile-inputs/${inputName}`,
      { value },
    );
  },
  editRequestProfileInputs({ websiteId, submissionId, values }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/submissions/${submissionId}/profile-inputs`,
      { values },
    );
  },
  editRequestCoordinates({ websiteId, submissionId, coordinates }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/submission/${submissionId}/coordinates`,
      { coordinates },
    );
  },
  addManualSubmission(websiteId, form) {
    return http.post(
      `${config.api.path}/websites/${websiteId}/submissions`,
      form,
    );
  },
  /**
   * Requests
   */
  postComment({ websiteId, submissionId, comment }) {
    return http.post(
      `${config.api.path}/websites/${websiteId}/submission/${submissionId}/comment`,
      { comment },
    );
  },
  attachFiles({ websiteId, submissionId, fileIds, fromAdmin }) {
    return http.post(
      `${config.api.path}/websites/${websiteId}/submission/${submissionId}/attach-files`,
      { fileIds, fromAdmin },
    );
  },
  deleteComment({ websiteId, commentId }) {
    return http.delete(
      `${config.api.path}/websites/${websiteId}/comments/${commentId}`,
    );
  },
  deleteFile({ websiteId, submissionId, fileId }) {
    return http.delete(
      `${config.api.path}/websites/${websiteId}/submission/${submissionId}/delete-file/${fileId}`,
    );
  },
  updateStatus(payload) {
    return http.put(
      `${config.api.path}/websites/${payload.websiteId}/submission/${payload.requestId}/update/status`,
      payload,
    );
  },
  updateRequestAssignees({ websiteId, requestId, assigneeIds }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/submission/${requestId}/assignees`,
      {
        assignee_ids: assigneeIds,
      },
    );
  },
  updateRequestGroups({ websiteId, requestId, groupIds }) {
    return http.put(
      `${config.api.path}/websites/${websiteId}/submission/${requestId}/group`,
      {
        group_ids: groupIds,
      },
    );
  },

  getQuestionTemplates(websiteId) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/questions?template=true`,
    );
  },

  cloneQuestion(websiteId, questionId) {
    return http.post(
      `${config.api.path}/websites/${websiteId}/questions/${questionId}/clone`,
    );
  },

  addCommunication(payload) {
    return http.post(
      `${config.api.path}/websites/${payload.websiteId}/submission/${payload.requestId}/communication/add`,
      payload,
    );
  },

  getWebsiteBrandProfiles(websiteId) {
    return http.get(`${config.api.path}/websites/${websiteId}/brand-profiles`);
  },

  getApplicationUrls(websiteId) {
    return http.get(
      `${config.api.path}/websites/${websiteId}/settings/app-urls`,
    );
  },

  getSocialBladeCount(platform, handle) {
    return http.get(`${config.api.path}/social-blade/${platform}/${handle}`);
  },

  /**
   * PUBLIC ROUTES
   */
  getSiteBySubdomain(subdomain) {
    return http.get(
      `${config.api.path}/frontend/websites/subdomain/${subdomain}`,
    );
  },
  getSiteByHostname(hostname) {
    if (process.env.VUE_APP_APP_ENV === "local") {
      hostname = hostname.replace(".localhost", "");
    }
    return http.get(
      `${config.api.path}/frontend/websites/hostname/${hostname}`,
    );
  },
  getPublicProperty(websiteId, propertyId, signature) {
    return http.get(
      `${config.api.path}/frontend/websites/${websiteId}/properties/${propertyId}?&signature=${signature}`,
    );
  },
  getPublicQuestions(websiteId) {
    return http.get(
      `${config.api.path}/frontend/websites/${websiteId}/questions`,
    );
  },
  getPublicStyles(websiteId) {
    return http.get(`${config.api.path}/frontend/websites/${websiteId}/styles`);
  },
  getPublicPage(websiteId, slug) {
    return http.get(
      `${config.api.path}/frontend/websites/${websiteId}/pages/${slug}`,
    );
  },
  getSiteProfileFields(websiteId) {
    return http.get(
      `${config.api.path}/frontend/websites/${websiteId}/profile-fields`,
    );
  },
  updatePublicPropertyForm(websiteId, propertyId, signature, form) {
    return http.put(
      `${config.api.path}/frontend/websites/${websiteId}/properties/${propertyId}?signature=${signature}`,
      form,
    );
  },
  updatePublicSharedProperty(websiteId, sharedPropertyId, signature, data) {
    return http.put(
      `${config.api.path}/frontend/websites/${websiteId}/properties/shared/${sharedPropertyId}?signature=${signature}`,
      data,
    );
  },
  validateProfileForm(websiteId, lang, form) {
    return http.post(
      `${config.api.path}/frontend/websites/${websiteId}/validation/profile?lang=${lang}`,
      form,
    );
  },
  validateGeneralForm(websiteId, lang, form) {
    return http.post(
      `${config.api.path}/frontend/websites/${websiteId}/validation/question?lang=${lang}`,
      form,
    );
  },
  postSubmission(websiteId, form) {
    return http.post(
      `${config.api.path}/frontend/websites/${websiteId}/submission`,
      form,
    );
  },
  logAttachment(log) {
    return http.post(`${config.api.path}/frontend/attachment-log`, log);
  },
  getPublicSocialBladeCount(propertyId, platform, handle, signature) {
    return http.get(
      `${config.api.path}/frontend/properties/${propertyId}/social-blade/${platform}/${handle}?signature=${signature}`,
    );
  },
  getBenefits() {
    return http.get(`${config.api.path}/benefits`);
  },
  getBenefit(id) {
    return http.get(`${config.api.path}/benefits/${id}`);
  },
  createBenefit(payload) {
    return http.post(`${config.api.path}/benefits`, payload);
  },
  updateBenefit(payload) {
    return http.put(`${config.api.path}/benefits/${payload.id}`, payload);
  },
  deleteBenefit(payload) {
    return http.delete(`${config.api.path}/benefits/${payload.id}`);
  },
  getBenefitsCategories() {
    return http.get(`${config.api.path}/benefits/categories`);
  },
  getBenefitCategory(id) {
    return http.get(`${config.api.path}/benefits/categories/${id}`);
  },
  createBenefitCategory(payload) {
    return http.post(`${config.api.path}/benefits/categories`, payload);
  },
  updateBenefitCategory(payload) {
    return http.put(
      `${config.api.path}/benefits/categories/${payload.id}`,
      payload,
    );
  },
  deleteBenefitCategory(payload) {
    return http.delete(`${config.api.path}/benefits/categories/${payload.id}`);
  },
};
