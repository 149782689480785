import _ from "lodash";
import api from "../../api";

export default {
  state: {
    benefitsCategories: [],
    fetched: false,
    loading: false,
  },

  mutations: {
    BENEFITS_CATEGORIES_START_FETCHING: (state) => {
      state.loading = true;
    },

    BENEFITS_CATEGORIES_FETCHED: (state, benefitsCategories) => {
      state.benefitsCategories = benefitsCategories;
      state.fetched = true;
      state.loading = false;
    },

    BENEFIT_CATEGORY_CREATED: (state, benefitCategory) => {
      state.benefitsCategories.push(benefitCategory);
    },

    BENEFIT_CATEGORY_UPDATED: (state, benefitCategory) => {
      const object = _.find(state.benefitsCategories, {
        id: benefitCategory.id,
      });

      if (object) {
        const index = state.benefitsCategories.indexOf(object);
        state.benefitsCategories.splice(index, 1, benefitCategory);
      }
    },

    BENEFIT_CATEGORY_DELETED: (state, id) => {
      const object = _.find(state.benefitsCategories, { id });

      if (object) {
        const index = state.benefitsCategories.indexOf(object);
        state.benefitsCategories.splice(index, 1);
      }
    },
  },

  actions: {
    fetchBenefitsCategories({ commit }) {
      commit("BENEFITS_CATEGORIES_START_FETCHING");
      return api.getBenefitsCategories().then((benefitsCategories) => {
        commit("BENEFITS_CATEGORIES_FETCHED", benefitsCategories);
      });
    },

    async createBenefitCategory({ commit }, payload) {
      try {
        const benefitCategory = await api.createBenefitCategory(payload);
        commit("BENEFIT_CATEGORY_CREATED", benefitCategory);
        return benefitCategory;
      } catch (error) {
        this.$noty.error(this.$i18n.t("admin.errors.internalError"));
      }
    },

    async updateBenefitCategory({ commit }, payload) {
      try {
        const benefitCategory = await api.updateBenefitCategory(payload);
        commit("BENEFIT_CATEGORY_UPDATED", benefitCategory);
        return benefitCategory;
      } catch (error) {
        this.$noty.error(this.$i18n.t("admin.errors.internalError"));
      }
    },

    async deleteBenefitCategory(commit, id) {
      try {
        await api.deleteBenefitCategory(id);
        commit("BENEFIT_CATEGORY_DELETED", id);
      } catch (error) {
        this.$noty.error(this.$i18n.t("admin.errors.internalError"));
      }
    },
  },
};
